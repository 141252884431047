import { ActionList } from '@sqs/rosetta-compositions';
import { ChevronDown } from '@sqs/rosetta-glyphs';
import { Checkmark } from '@sqs/rosetta-icons';
import { Button, Flex, Text } from '@sqs/rosetta-primitives';
import { useTheme } from '@sqs/rosetta-styled';
import React, { useState } from 'react';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { EnterpriseUser } from '../../models/EnterpriseUser';
import { T, t } from '../../i18n';
import { SqspUser } from '../../models/SqspUser';
import { isInSqspOrg } from '../../utils/sqspUserUtils';

interface AccountSwitcherProps {
  readonly currentUser: EnterpriseUser | SqspUser;
}

// TODO: Add fetch logic for SQSP Enterprise Dashboard data https://squarespace.atlassian.net/browse/AE-378
export const AccountSwitcher = ({ currentUser }: AccountSwitcherProps) => {
  const { isMobile } = usePlatformBreakpoint();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const { space } = useTheme();
  if (!isInSqspOrg(currentUser)) {
    return <></>;
  }

  const toggleMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <ActionList.PopOver
      isOpen={isMenuOpen}
      renderTrigger={() =>
      <Button.Tertiary aria-label="Open menu" onClick={toggleMenuOpen}>
          {isMobile ?
        <img
          style={{ width: '24px' }}
          src={require('../../assets/acuity-icon.svg')}
          alt={t("{business} Acuity Enterprise",

          { business: currentUser.enterprise.name }, {
            project: 'enterprise-dashboard' })
          } /> :


        currentUser.enterprise.name
        }
          <ChevronDown
          data-testid="account-switcher-svg"
          sx={{
            transition: 'transform 0.2s ease-in-out',
            marginLeft: space[1],
            width: '22px',
            height: '22px',
            transform: isMenuOpen ? 'rotate(180deg)' : ''
          }} />

        </Button.Tertiary>
      }
      anchorPoint={{ y: 'bottom' }}
      position="bottom">

      {() =>
      <Flex sx={{ background: 'white' }} flexDirection="column" width="290px">
          <ActionList.Item
          onClick={() => {
            toggleMenuOpen();
          }}
          py={2}>

            <Flex alignItems="center" gap={2}>
              <div>
                <Text.Body my={0} textTransform="uppercase">
                  {currentUser.enterprise.name}
                </Text.Body>
              </div>
              <Checkmark />
            </Flex>
          </ActionList.Item>
          <ActionList.Item
          onClick={() => {
            toggleMenuOpen();
            window.location.href = __SQSP_ACCOUNT_URL__;
          }}
          py={2}>

            <Flex alignItems="center" gap={2}>
              <div>
                <Text.Body my={0} textTransform="uppercase">
                  <T project="enterprise-dashboard">{"Squarespace Personal"}</T>
                </Text.Body>
              </div>
            </Flex>
          </ActionList.Item>
        </Flex>
      }
    </ActionList.PopOver>);

};