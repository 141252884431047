export const V1_API_BASE_URL = `${__SCHEDULING_HOST__}/api/enterprise/v1`;
export const V2_API_BASE_URL = `${__SCHEDULING_HOST__}/api/enterprise/v2`;

export const V1_ENTERPRISE_BASE_URL = `${V1_API_BASE_URL}/enterprises`;
export const V2_ENTERPRISE_BASE_URL = `${V2_API_BASE_URL}/enterprises`;

export interface ApiError {
  key: string;
  value: string;
  rule: string;
  error: string;
}
