import { t } from '../i18n';
import { PageInfoQueryWithSearch, PaginatedResponse } from '../models/PaginatedResponse';
import { SchedulingInstance } from '../models/SchedulingInstance';
import { OrgUser, OrgUserFetchResponse } from '../models/SqspUser';
import { V2_ENTERPRISE_BASE_URL } from './api';
import axios from './axios';
import { appendPaginationInfo } from './helpers';

export interface CreateOrgUserData {
  name: string;
  email: string;
  orgRole: string;
}

export interface CreateOrgUserResponse {
  error?: boolean;
  message?: string;
  data?: OrgUser;
}

interface ListSchedulingInstancesResponse extends PaginatedResponse {
  data: SchedulingInstance[];
}

export const listOrgUsers = async (
enterpriseId: number,
pageInfo?: PageInfoQueryWithSearch)
: Promise<OrgUserFetchResponse> => {
  const url = appendPaginationInfo(new URL(`${V2_ENTERPRISE_BASE_URL}/${enterpriseId}/users`), pageInfo);
  const response = await axios.get<OrgUserFetchResponse>(url.toString());
  return response.data;
};

export const listInvitedOrgUsers = async (
enterpriseId: number,
pageInfo?: PageInfoQueryWithSearch)
: Promise<OrgUserFetchResponse> => {
  const url = appendPaginationInfo(new URL(`${V2_ENTERPRISE_BASE_URL}/${enterpriseId}/users/invited`), pageInfo);
  const response = await axios.get<OrgUserFetchResponse>(url.toString());
  return response.data;
};

export const createSqspOrgUser = async (
enterpriseId: number,
userData: CreateOrgUserData)
: Promise<CreateOrgUserResponse> => {
  try {
    const response = await axios.post<CreateOrgUserResponse>(
      `${V2_ENTERPRISE_BASE_URL}/${enterpriseId}/user-invitations`,
      {
        ...userData
      }
    );
    return response.data;
  } catch (error) {
    return Promise.reject({
      error: true,
      message: t("There was an error creating the user invitation.", null, { project: 'enterprise-dashboard' })
    });
  }
};

export const fetchOrgUserData = async (enterpriseId: number, userId: string | number): Promise<OrgUser | null> => {
  const response = await axios.get(`${V2_ENTERPRISE_BASE_URL}/${enterpriseId}/users/${userId}`);
  return response.data;
};

export const updateUserInOrg = async (enterpriseId: number, orgRole: string, orgUserId: number) => {
  const response = await axios.put(`${V2_ENTERPRISE_BASE_URL}/${enterpriseId}/users/${orgUserId}`, {
    orgRole
  });
  return response.data;
};

export const removeUserFromOrg = async (enterpriseId: number, userId: number) => {
  const response = await axios.delete(`${V2_ENTERPRISE_BASE_URL}/${enterpriseId}/users/${userId}`);
  return response.data;
};

export const fetchUserSchedulingInstances = async (
enterpriseId: number,
userId: number)
: Promise<ListSchedulingInstancesResponse> => {
  const response = await axios.get(`${V2_ENTERPRISE_BASE_URL}/${enterpriseId}/users/${userId}/instances`);
  return response.data;
};

export const resendInviteToUser = async (enterpriseId: number, inviteCode: string | null) => {
  const response = await axios.post(`${V2_ENTERPRISE_BASE_URL}/${enterpriseId}/user-invitations/${inviteCode}/resend`);
  return response.data;
};

export const cancelUserInvite = async (enterpriseId: number, inviteCode: string | null) => {
  const response = await axios.delete(`${V2_ENTERPRISE_BASE_URL}/${enterpriseId}/user-invitations/${inviteCode}`);
  return response.data;
};