import { Dropdown } from '@sqs/rosetta-compositions';
import { Cell, TextInput } from '@sqs/rosetta-elements';
import { Box } from '@sqs/rosetta-primitives';
import { useTheme } from '@sqs/rosetta-styled';
import { Keyboard } from '@sqs/rosetta-utilities';
import React, { ReactNode } from 'react';

interface TextInputCellProps {
  readonly onChange: (text: string) => void;
  readonly setIsOpen: (val: boolean) => void;
  readonly value: string;
  readonly isDateValid: (val: string) => boolean;
}

const TextInputCell = React.forwardRef(
  ({ onChange, setIsOpen, value, isDateValid, ...rest }: TextInputCellProps, ref) => {
    const { radii, space } = useTheme();
    const [inputVal, setInputVal] = React.useState('');

    React.useEffect(() => {
      setInputVal(value);
    }, [value]);

    return (
      <Cell
        ref={ref}
        as="label"
        border="1px solid"
        borderColor="gray.600"
        borderRadius={radii[1]}
        padding={space[2]}
        body={(
          <TextInput
            onBlur={() => {
              if (!isDateValid(inputVal) || inputVal === value) {
                setInputVal(value ?? '');
              } else {
                onChange(inputVal);
              }
            }}
            onChange={setInputVal}
            onClick={() => setIsOpen(true)}
            onFocus={() => setIsOpen(true)}
            onKeyDown={(e: KeyboardEvent) => {
              if (Keyboard.isTab(e)) {
                if (!isDateValid(inputVal)) {
                  setInputVal(value ?? '');
                } else {
                  onChange(inputVal);
                }
                setIsOpen(false);
              }
              if (Keyboard.isEsc(e)) {
                setIsOpen(false);
              }
            }}
            placeholder="mm/dd/yyyy"
            textAlign="left"
            value={inputVal}
          />
        )}
        {...rest}
      />
    );
  },
);

interface EditableDropdownDatepickerProps {
  readonly children: ReactNode;
  readonly title: string;
  readonly onRequestClose: () => void;
  readonly exteriorPre: ReactNode;
  readonly isDateValid: (val: string) => boolean;
  readonly isOpen: boolean;
  readonly onChange: (text: string) => void;
  readonly setIsOpen: (val: boolean) => void;
  readonly value: string;
}

export const EditableDropdownDatepicker = React.forwardRef(
  ({ children, title, onRequestClose, ...rest }: EditableDropdownDatepickerProps, ref) => {
    return (
      <Box>
        <Dropdown
          {...rest}
          ref={ref}
          renderDisplay={TextInputCell}
          renderDropdown={(props: any) => (
            <Dropdown.Dropdown {...props} onBlur={onRequestClose} placement="bottom" px={2} />
          )}
        >
          {children}
        </Dropdown>
      </Box>
    );
  },
);
