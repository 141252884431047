// TODO: Implement actual submission for SSO Credentials
import axios from './axios';

export interface SsoCredentialData {
  provider: string;
  clientId?: string;
  clientSecret?: string;
  x509Certificate?: string;
  ssoUrl: string;
}

export const submitSsoCredentials = async (enterpriseId: number, ssoCredentials: SsoCredentialData) => {
  return Promise.resolve({ status: 200 });
};
