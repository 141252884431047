import { Cell, Table, TableGridRecordProps } from '@sqs/rosetta-experimental';
import { Flex, Text } from '@sqs/rosetta-primitives';
import React, { useContext, useEffect } from 'react';
import { FEATURE_FLAGS } from '../../const/feature-flags';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { T, t } from '../../i18n';
import { OrgUser } from '../../models/SqspUser';
import { EmptyResults } from '../common/EmptyResults';
import { TablePagination } from '../common/Table/TablePagination';
import { CreateUser } from '../CreateUser/CreateUser';
import { FeatureFlagContext } from '../FeatureFlagProvider';

export interface UsersListResultsTableDataProps {
  readonly hasLoaded: boolean;
  readonly users: OrgUser[];
  readonly invitedUsersOnly: boolean;
  readonly enterpriseId: number;
}

export const UsersListResultsTableData = ({ hasLoaded, users, invitedUsersOnly }: UsersListResultsTableDataProps) => {
  const { isMobile } = usePlatformBreakpoint();
  const { flagEnabled } = useContext(FeatureFlagContext);

  // TODO: Remove with AE-634
  const filterUsersByInviteStatus = flagEnabled(FEATURE_FLAGS.ENTERPRISE_SEPARATE_INVITED_USER);

  const table = Table.useTable();

  if (!Object.keys(table).length) {
    throw new Error('UsersListResultsTableData has to be used within <Table>');
  }

  // we only want to show the status of a user on the invited tab
  useEffect(() => {
    if (filterUsersByInviteStatus) {
      table.setState((prev) => ({
        ...prev,
        columnVisibility: { status: invitedUsersOnly }
      }));
    }
  }, [invitedUsersOnly, filterUsersByInviteStatus]);

  if (table.getState().globalFilter && table.getRowCount() === 0) {
    return (
      <EmptyResults
        title={t("There are no users in your organization that match your search.",

        {}, {
          project: 'enterprise-dashboard' })
        }
        subtitle={t("Try another search.", {}, { project: 'enterprise-dashboard' })} />);


  }

  if (hasLoaded && !users.length) {
    if (invitedUsersOnly) {
      return (
        <EmptyResults
          title={t("Your organization has no users pending invite.", {}, { project: 'enterprise-dashboard' })}
          actionButton={<CreateUser showFullButton />} />);


    }
    return (
      <EmptyResults
        title={t("There are no users in your organization.", {}, { project: 'enterprise-dashboard' })}
        actionButton={<CreateUser showFullButton />} />);


  }

  return (
    <>
      {!isMobile && <Table.List />}
      {isMobile &&
      <Table.Grid
        sx={{
          gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr))',
          marginTop: 3
        }}
        renderRecord={({ record }: TableGridRecordProps<OrgUser>) => {
          if (!record) {
            return null;
          }

          const cells = Object.fromEntries(
            record.getVisibleCells().map((cell: Cell<OrgUser, any>) => [cell.column.id, cell])
          );
          return (
            <Table.Grid.Record>
                <Flex>
                  <Flex flexDirection="column" gap={1} p={3} width="100%">
                    <Flex flexDirection="column">
                      <Flex justifyContent="space-between">
                        <Table.Grid.Record.Cell cell={cells.name} />
                        <Table.Grid.Record.Cell cell={cells.id} />
                      </Flex>
                    </Flex>
                    <Flex gap={2} alignItems="center">
                      <Text.Caption fontSize={3}>
                        <T project="enterprise-dashboard">{"Role"}</T>
                      </Text.Caption>
                      <Table.Grid.Record.Cell cell={cells.role} />
                    </Flex>
                    <Flex gap={2} alignItems="center">
                      <Text.Caption fontSize={3}>
                        <T project="enterprise-dashboard">{"Status"}</T>
                      </Text.Caption>
                      <Table.Grid.Record.Cell mt={-1} cell={cells.status} />
                    </Flex>
                  </Flex>
                </Flex>
              </Table.Grid.Record>);

        }} />

      }
    </>);

};