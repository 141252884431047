import { Text, TextProps } from '@sqs/rosetta-primitives';
import React from 'react';

type ModalTitleProps = TextProps;

export const ModalTitle = ({ children, ...props }: ModalTitleProps) => {
  return (
    <Text.Body mx={0} mt={0} mb={5} fontSize={6} fontWeight={500} {...props}>
      {children}
    </Text.Body>
  );
};
