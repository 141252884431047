import { CheckmarkCircle } from '@sqs/rosetta-icons';
import { Box, Button, Flex, Text } from '@sqs/rosetta-primitives';
import React, { ReactNode, useEffect } from 'react';
import { T } from '../../../i18n';
import { usePlatformBreakpoint } from '../../../hooks/usePlatformBreakpoint';
import { GenericModal } from '../../common/GenericModal';
import { ModalTitle } from '../../common/ModalTitle';

interface FirstApiKeyModalProps {
  readonly closeModal: () => void;
  readonly resetApiKey: () => void;
  readonly showApiKey: (viewOnly?: boolean) => ReactNode;
}

export const FirstApiKeyModal = ({ closeModal, resetApiKey, showApiKey }: FirstApiKeyModalProps) => {
  const { isMobile } = usePlatformBreakpoint();
  useEffect(() => {
    resetApiKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <GenericModal
      closeModal={closeModal}
      modalActions={
      <Flex justifyContent="flex-end">
          <Button.Primary onClick={closeModal} size="large" width={isMobile ? '100%' : 'auto'}>
            <T project="enterprise-dashboard">{"Close"}</T>
          </Button.Primary>
        </Flex>
      }>

      <Flex alignItems="center" gap={1} mb={4}>
        <CheckmarkCircle color="green.400" />
        <ModalTitle>
          <T project="enterprise-dashboard">{"API Key has been generated"}</T>
        </ModalTitle>
      </Flex>
      <Text.Body>
        <T project="enterprise-dashboard">{"Keep your API key somewhere safe. For your security, we\u2019ll only show the full key in this session. Once you exit this session and come back, you won\u2019t be able to see the full key anymore."}</T>



      </Text.Body>

      <Box px={4} py={4} mb={!isMobile ? 3 : 0} backgroundColor="gray.800">
        <Text.Body fontWeight={500} m={0}>
          <T project="enterprise-dashboard">{"API Key"}</T>
        </Text.Body>
        {showApiKey(true)}
      </Box>
    </GenericModal>);

};