import { Grid, Modal } from '@sqs/rosetta-elements';
import { Box, Button, Flex, Text } from '@sqs/rosetta-primitives';
import { useTheme } from '@sqs/rosetta-styled';
import { T, t } from '../../i18n';
import React, { useState } from 'react';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { PageHeader } from '../PageHeader';
import { ExportAppointmentsModal } from './ExportAppointmentsModal';

export const Reporting = () => {
  const { borders, colors, radii } = useTheme();
  const { isMobile } = usePlatformBreakpoint();
  const [showExportAppointmentsModal, setShowExportAppointmentsModal] = useState(false);

  return (
    <Box>
      <PageHeader title={t("Reporting", {}, { project: 'enterprise-dashboard' })} />
      <Grid.Container gridConstraint={12} margin={0} gutter={0}>
        <Grid.Item columns={[12, 9, 8]} p={0}>
          <Flex
            css={{
              borderRadius: radii[2],
              border: `${borders[1]} ${colors.gray['700']}`,
              padding: isMobile ? '24px' : '20px 40px 40px'
            }}
            justifyContent="space-between"
            alignItems="center"
            flexDirection={isMobile ? 'column' : 'row'}>

            <Box>
              <Text.Subtitle mb={0} mt={isMobile ? 0 : ''}>
                <T project="enterprise-dashboard">{"Export Appointments"}</T>
              </Text.Subtitle>
              <Text.Body my={2}>
                <T project="enterprise-dashboard">{"Customize a report of appointments and details"}</T>
              </Text.Body>
            </Box>
            <Button.Secondary
              type="button"
              size="medium"
              width={isMobile ? '100%' : ''}
              onClick={() => setShowExportAppointmentsModal(true)}>

              <T project="enterprise-dashboard">{"Create report"}</T>
            </Button.Secondary>
          </Flex>
        </Grid.Item>
      </Grid.Container>
      <Modal.Transition>
        {showExportAppointmentsModal &&
        <ExportAppointmentsModal closeModal={() => setShowExportAppointmentsModal(false)} />
        }
      </Modal.Transition>
    </Box>);

};