import { INITIAL_LIMIT } from '../const/pagination';
import { PageInfoQueryWithSearch } from '../models/PaginatedResponse';

export const appendPaginationInfo = (url: URL, pageInfo?: PageInfoQueryWithSearch) => {
  url.searchParams.append('offset', `${pageInfo?.offset || 0}`);
  url.searchParams.append('limit', `${pageInfo?.limit || INITIAL_LIMIT}`);
  url.searchParams.append('sort', `${pageInfo?.sort || ''}`);
  url.searchParams.append('dir', `${pageInfo?.dir || 'asc'}`);
  if (pageInfo?.search) {
    url.searchParams.append('search', `${pageInfo.search}`);
  }

  return url;
};
