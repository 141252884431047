import { ApiKey } from '../models/ApiKey';
import { Enterprise } from '../models/Enterprise';
import { V2_ENTERPRISE_BASE_URL } from './api';
import axios from './axios';

export const getApiKey = async (enterpriseId: number): Promise<ApiKey> => {
  const response = await axios.get<ApiKey>(`${V2_ENTERPRISE_BASE_URL}/${enterpriseId}/api-key`);
  return response.data;
};

export const updateApiKey = async (enterpriseId: number): Promise<ApiKey> => {
  const response = await axios.post<ApiKey>(`${V2_ENTERPRISE_BASE_URL}/${enterpriseId}/api-key`);
  return response.data;
};

export const updateEnterpriseInformation = async (enterpriseId: number, name: string): Promise<Enterprise> => {
  const response = await axios.patch<Enterprise>(`${V2_ENTERPRISE_BASE_URL}/${enterpriseId}`, {
    name,
  });
  return response.data;
};
