import { Divider, Grid } from '@sqs/rosetta-elements';
import { Box, Flex, Text, Touchable } from '@sqs/rosetta-primitives';
import { useTheme } from '@sqs/rosetta-styled';
import React, { useState } from 'react';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { T } from '../../i18n';
import { Enterprise } from '../../models/Enterprise';
import { EnterpriseUser } from '../../models/EnterpriseUser';
import { SqspUser } from '../../models/SqspUser';
import { isInSqspOrg } from '../../utils/sqspUserUtils';
import { AccountSettingsModal } from '../AccountSettingsModal';
import { DEFAULT_ACCOUNT_MANAGER_EMAIL } from '../Settings/OrganizationInfo';
import { AccountSwitcher } from './AccountSwitcher';
import { ProfileDropdown } from './ProfileDropdown';
import { TabNav } from './TabNav';

interface NavBarProps {
  readonly currentUser: SqspUser | EnterpriseUser;
  readonly enterprise: Enterprise;
}

export function NavBar({ currentUser, enterprise }: NavBarProps) {
  const { space } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = usePlatformBreakpoint();

  const accountManagerContact = enterprise.accountManager?.email ?
  enterprise.accountManager?.email :
  DEFAULT_ACCOUNT_MANAGER_EMAIL;
  const isSqspEnterprise = isInSqspOrg(currentUser);
  const username = 'email' in currentUser ? currentUser.email : currentUser.username;

  let logoCss = {};
  if (isMobile) {
    logoCss = { textAlign: 'center', width: '100%' };
  }

  return (
    <>
      <Grid.Container alignItems="center" gridConstraint={12}>
        {/* Left section */}
        <Grid.Item m={'auto'} columns={[6, 2]}>
          <Box css={{ marginTop: space[3], ...logoCss }}>
            <img src={require('../../assets/acuity-scheduling-logo.svg')} alt="Acuity Scheduling Logo" />
          </Box>
        </Grid.Item>
        {isMobile &&
        <Grid.Item m={'auto'} columns={[6]}>
            <Flex justifyContent="flex-end">
              <AccountSwitcher currentUser={currentUser} />
            </Flex>
          </Grid.Item>
        }
        {/* Center section */}
        <Grid.Item m={'auto'} columns={[8, 6]}>
          <TabNav />
        </Grid.Item>
        <Grid.Item m={'auto'} columns={[4, 3]}>
          <Flex justifyContent="flex-end">
            {!isMobile && <AccountSwitcher currentUser={currentUser} />}
            {/* "Help" lives in the Profile Dropdown for SQSP users */}
            {!isSqspEnterprise &&
            <Touchable as="span" interaction={Touchable.Underline} mx={2}>
                <Text.Body
                as="a"
                href="https://help.acuityscheduling.com/"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ fontWeight: 'medium' }}>

                  <T project="enterprise-dashboard">{"Help"}</T>
                </Text.Body>
              </Touchable>
            }
            <ProfileDropdown
              name={enterprise.name}
              username={username}
              isInSqspOrg={isSqspEnterprise}
              onAccountSettingsClick={() => setIsOpen(true)}
              accountManagerEmail={accountManagerContact} />

          </Flex>
        </Grid.Item>
        <Grid.Item columns={[0, 1]} />
      </Grid.Container>
      <Divider css={{ marginTop: isMobile ? '-5px' : '-1px' }} />
      <AccountSettingsModal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} />
    </>);

}