import { disableGlobalLoader } from '../stores/applicationSettings';
import { useAppDispatch } from '../stores/store';

export const usePageCache = (): {
  disableGlobalLoaderBfcacheEvent: () => void;
  removeDisableGlobalLoaderBfcacheEvent: () => void;
} => {
  const dispatch = useAppDispatch();

  /**
   * Modern browsers store page state in cache for faster back/forward pagination:
   *   https://web.dev/articles/bfcache
   *
   * When a user navigates to a Scheduling Instance in the dashboard, we show the global loader while their permissions
   * for that instance are loaded. If they click the browser back button, the global loader is still "enabled" and they
   * cannot use the page unless they refresh.
   *
   * In order to ensure our global loader is disabled when a user clicks the back button coming from Acuity, this
   * listener checks for the `event.persisted` value on the `pageshow` browser event and dispatches an event to hide
   * the global loader.
   */
  const addDisableGlobalLoaderBfcacheEvent = (event: PageTransitionEvent) => {
    if (event.persisted) {
      dispatch(disableGlobalLoader());
    }
  };

  const disableGlobalLoaderBfcacheEvent = () => {
    window.addEventListener('pageshow', addDisableGlobalLoaderBfcacheEvent);
  };

  const removeDisableGlobalLoaderBfcacheEvent = () => {
    window.removeEventListener('pageshow', addDisableGlobalLoaderBfcacheEvent);
  };

  return { disableGlobalLoaderBfcacheEvent, removeDisableGlobalLoaderBfcacheEvent };
};
