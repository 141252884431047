import { Banner } from '@sqs/rosetta-compositions';
import { Divider, Modal } from '@sqs/rosetta-elements';
import { CheckmarkCircle } from '@sqs/rosetta-icons';
import { Box, Button, Flex, Text } from '@sqs/rosetta-primitives';
import { useTheme } from '@sqs/rosetta-styled';
import debounce from 'lodash/debounce';
import React, { useCallback, useState } from 'react';
import { Control, useForm, useWatch } from 'react-hook-form';
import { validateSubdomain, ValidateSubdomainResponse } from '../../apis/schedulingInstances';
import { T, t } from '../../i18n';
import { Enterprise } from '../../models/Enterprise';
import { selectEnterprise } from '../../stores/currentUser';
import { RootState } from '../../stores/rootReducer';
import { createSqspSchedulingInstanceAction } from '../../stores/schedulingInstances';
import { useAppDispatch, useAppSelector } from '../../stores/store';
import { preventSubmit } from '../../utils/keyboard';
import { cleanSubdomainUrl } from '../../utils/text';
import { AcuityTextField } from '../common/AcuityTextField';
import { GenericModal } from '../common/GenericModal';
import { ModalTitle } from '../common/ModalTitle';

interface CreateSchedulingInstanceModalProps {
  readonly closeModal: () => void;
  readonly isOpen: boolean;
}

interface NewInstanceForm {
  businessName: string;
  subdomain: string;
}

const VALIDATION_TIMEOUT_IN_MS = 300;

const WatchedBanner = ({ control }: {readonly control: Control<NewInstanceForm>;}) => {
  const subdomain = useWatch({
    control,
    name: 'subdomain',
    defaultValue: '' // '' value before the render
  });

  const pageUrl = `https://${subdomain}.as.me`;
  return (
    <Banner
      data-testid="shorturl-preview"
      my={4}
      layout="row"
      title={<T project="enterprise-dashboard">{"Scheduling Page URL Preview"}</T>}
      body={pageUrl}
      icon={<></>}
      css={{ paddingLeft: 0 }} />);


};

export const CreateSchedulingInstanceModal = ({ closeModal, isOpen }: CreateSchedulingInstanceModalProps) => {
  const { colors, space } = useTheme();
  const dispatch = useAppDispatch();
  const { enterprise, pageInfo } = useAppSelector((state: RootState) => ({
    enterprise: selectEnterprise(state.currentUser) as Enterprise,
    pageInfo: state.schedulingInstances.pageInfo
  }));
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(false);

  const {
    control,
    formState: { errors, isValid, dirtyFields },
    handleSubmit,
    register,
    watch,
    reset,
    trigger,
    setValue,
    setError,
    clearErrors
  } = useForm<NewInstanceForm>({
    defaultValues: {
      businessName: '',
      subdomain: ''
    }
  });
  register('subdomain');
  register('businessName', {
    required: t("Business name is required", {}, { project: 'enterprise-dashboard' }),
    minLength: {
      value: 2,
      message: t("Please enter a name longer than 2 characters", {}, { project: 'enterprise-dashboard' })
    }
  });

  // debounce for functional components needs useCallback
  const validateSubdomainDebounced = useCallback(
    debounce((url: string) => {
      if (url.length < 2 && url !== '') {
        setError('subdomain', {
          type: 'minLength',
          message: t("Please enter a url longer than 2 characters", {}, { project: 'enterprise-dashboard' })
        });
      } else {
        clearErrors('subdomain');
        validateSubdomain(url).then(async ({ error, message, available, status }: ValidateSubdomainResponse) => {
          if (available || url === '' || status === 204) {
            clearErrors('subdomain');
            await trigger();
            setSaveButtonLoading(false);
            if (available) {
              setIsValidUrl(true);
            }
          } else if (available !== undefined && !available) {
            setError('subdomain', { type: 'taken', message });
          } else if (error && status === 400) {
            setError('subdomain', { type: 'invalid', message });
          } else if (!status) {
            setError('subdomain', { type: 'error', message });
          }
        });
      }
    }, VALIDATION_TIMEOUT_IN_MS),
    []
  );

  const triggerValidateSubdomain = (url: string, shouldDirty?: boolean) => {
    const parsedUrl = cleanSubdomainUrl(url);
    setValue('subdomain', parsedUrl, { shouldDirty });
    setSaveButtonLoading(true);
    setIsValidUrl(false);

    validateSubdomainDebounced(parsedUrl);
  };

  const close = () => {
    reset();
    closeModal();
  };

  const handleSubmitNewInstance = handleSubmit((formData) => {
    const data = { ...formData };
    if (!data.subdomain && data.businessName) {
      data.subdomain = cleanSubdomainUrl(data.businessName);
    }
    if (enterprise) {
      dispatch(createSqspSchedulingInstanceAction(enterprise.id, data, pageInfo));
    }
    close();
  });

  const subDomainValue = watch('subdomain');

  return (
    <Modal.Transition>
      {isOpen &&
      <GenericModal
        closeModal={close}
        width="508px"
        xPadding="96px"
        modalActions={
        <Flex justifyContent="space-between">
              <Button.Tertiary size="large" type="button" onClick={close}>
                <T project="enterprise-dashboard">{"Cancel"}</T>
              </Button.Tertiary>
              <Button.Primary
            size="large"
            type="submit"
            onClick={handleSubmitNewInstance}
            disabled={!isValid || saveButtonLoading}>

                <T project="enterprise-dashboard">{"Save"}</T>
              </Button.Primary>
            </Flex>
        }>

          <Box>
            <ModalTitle>
              <T project="enterprise-dashboard">{"Add New Scheduling Instance"}</T>
            </ModalTitle>
            <Text.Body>
              <T project="enterprise-dashboard">{"Once you create a Scheduling Instance, you\u2019ll be able to customize your scheduling page and start booking appointments."}</T>



            </Text.Body>
            <Divider my={4} />
            <Text.Label htmlFor="businessName">
              <T project="enterprise-dashboard">{"Business Name"}</T>
            </Text.Label>
            <Text.Caption>
              <T project="enterprise-dashboard">{"This will be the name shown on the scheduling page"}</T>
            </Text.Caption>
            <AcuityTextField
            inputProps={{
              id: 'businessName',
              onChange: (v: string) => {
                setValue('businessName', v);
                trigger('businessName');
                if (!dirtyFields.subdomain) {
                  setValue('subdomain', cleanSubdomainUrl(v));
                  triggerValidateSubdomain(v);
                }
              },
              value: watch('businessName'),
              onKeyDown: preventSubmit,
              placeholder: t("Business name", {}, { project: 'enterprise-dashboard' })
            }} />

            {errors.businessName &&
          <Text.Caption sx={{ color: colors.red[400] }} role="alert">
                {errors.businessName.message}
              </Text.Caption>
          }
            <Box mt={4}>
              <Text.Label htmlFor="subdomain">
                <T project="enterprise-dashboard">{"Scheduling Page URL (Optional)"}</T>
              </Text.Label>
              <Text.Caption>
                <T project="enterprise-dashboard">{"This is the URL of your scheduling page and can be updated any time. Only alphanumeric characters are allowed."}</T>



              </Text.Caption>
              <AcuityTextField
              inputProps={{
                id: 'subdomain',
                onChange: (v: string) => triggerValidateSubdomain(v, true),
                value: subDomainValue,
                onKeyDown: preventSubmit,
                placeholder: t("Scheduling Page URL", {}, { project: 'enterprise-dashboard' })
              }}
              interiorPost={
              isValidUrl && !saveButtonLoading && isValid ?
              <CheckmarkCircle color="green.500" title="checkmark-circle" sx={{ marginBottom: '-2px' }} /> :
              null
              } />

              {errors.subdomain &&
            <Text.Caption sx={{ color: colors.red[400] }} role="alert">
                  {errors.subdomain.message}
                </Text.Caption>
            }
            </Box>
            <WatchedBanner control={control} />
          </Box>
        </GenericModal>
      }
    </Modal.Transition>);

};