import React, { Context, Dispatch, PropsWithChildren, ReactElement, SetStateAction, useState } from 'react';
import { ZIndexStack } from '@sqs/rosetta-utilities';
import { Toast } from '@sqs/rosetta-elements';
import { useAppSelector } from '../stores/store';
import { RootState } from '../stores/rootReducer';

export type ToastManagerContextValue = InstanceType<typeof Toast.Container> | undefined;
type ToastManagerProviderProps = PropsWithChildren<any>;

export const ToastManagerContext: Context<ToastManagerContextValue> =
  React.createContext<ToastManagerContextValue>(undefined);

export function ToastManagerProvider(props: ToastManagerProviderProps): ReactElement {
  const { currentUser } = useAppSelector((state: RootState) => ({
    isLoading: state.currentUser.isLoading,
    currentUser: state.currentUser.currentUser,
  }));

  const [ref, setRef]: [ToastManagerContextValue, Dispatch<SetStateAction<ToastManagerContextValue>>] =
    useState<ToastManagerContextValue>(undefined);

  function handleRef(toastManagerRef: InstanceType<typeof Toast.Container>): void {
    setRef(toastManagerRef || undefined);
  }

  const toastStyle: Partial<CSSStyleDeclaration> = {};
  // NOTE: Temporarily disabling this override until a design decision is made and a fix for this in Rosetta is (maybe) added
  // currentUser && currentUser.isInSqspOrg ? { top: '0', bottom: 'auto', flexDirection: 'column-reverse' } : {};

  return (
    <>
      <ZIndexStack>
        {(zIndex) => <Toast.Container ref={handleRef} zIndex={zIndex + 500} style={toastStyle} />}
      </ZIndexStack>
      <ToastManagerContext.Provider value={ref}>{props.children}</ToastManagerContext.Provider>
    </>
  );
}
