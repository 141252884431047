import { Cell, Table, TableGridRecordProps } from '@sqs/rosetta-experimental';
import { Flex, Text } from '@sqs/rosetta-primitives';
import React from 'react';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { T, t } from '../../i18n';
import { SchedulingInstance } from '../../models/SchedulingInstance';
import { ORG_MANAGER, OrgUser } from '../../models/SqspUser';
import { EmptyResults } from '../common/EmptyResults';
import { TablePagination } from '../common/Table/TablePagination';
import { CreateAccount } from '../CreateAccount';

interface SchedulingInstancesListResultsTableDataProps {
  readonly currentUser: OrgUser;
}

export const SchedulingInstancesListResultsTableData = ({
  currentUser
}: SchedulingInstancesListResultsTableDataProps) => {
  const { isMobile } = usePlatformBreakpoint();
  const table = Table.useTable();

  if (!Object.keys(table).length) {
    throw new Error('SchedulingInstancesListResultsTableData has to be used within <Table>');
  }

  if (table.getState().globalFilter && table.getRowCount() === 0) {
    return (
      <EmptyResults
        title={t("There are no instances that match your search.", {}, { project: 'enterprise-dashboard' })}
        subtitle={t("Try another search.", {}, { project: 'enterprise-dashboard' })} />);


  }

  if (table?.getRowCount() === 0) {
    if (currentUser.role === ORG_MANAGER) {
      return (
        <EmptyResults
          title={t("You don\u2019t have any Scheduling Instances for your org yet.",

          {}, {
            project: 'enterprise-dashboard' })
          }
          subtitle={t("Create a Scheduling Instance to start managing your appointments.",

          {}, {
            project: 'enterprise-dashboard' })
          }
          actionButton={<CreateAccount showFullButton />} />);


    }

    return (
      <EmptyResults
        title={t("You don\u2019t have access to any Scheduling Instances for your org yet.",

        {}, {
          project: 'enterprise-dashboard' })
        } />);


  }
  return (
    <>
      {!isMobile && <Table.List />}
      {isMobile &&
      <Table.Grid
        sx={{
          gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr))',
          marginTop: 3
        }}
        renderRecord={({ record }: TableGridRecordProps<SchedulingInstance>) => {
          if (!record) {
            return null;
          }

          const cells = Object.fromEntries(
            record.getVisibleCells().map((cell: Cell<SchedulingInstance, any>) => [cell.column.id, cell])
          );
          return (
            <Table.Grid.Record>
                <Flex>
                  <Flex flexDirection="column" p={3} gap={0.5} width="100%">
                    <Flex flexDirection="column" mb={1}>
                      <Flex justifyContent="space-between">
                        <Table.Grid.Record.Cell sx={{ fontWeight: 500 }} cell={cells.instanceName} />
                        <Table.Grid.Record.Cell cell={cells.id} />
                      </Flex>
                    </Flex>
                    <Flex gap={2} alignItems="center">
                      <Text.Caption fontSize={3}>
                        <T project="enterprise-dashboard">{"# of calendars"}</T>
                      </Text.Caption>
                      <Table.Grid.Record.Cell cell={cells.calendarCount} />
                    </Flex>
                    <Flex gap={2} alignItems="center">
                      <Text.Caption fontSize={3}>
                        <T project="enterprise-dashboard">{"# of users"}</T>
                      </Text.Caption>
                      <Table.Grid.Record.Cell cell={cells.userCount} />
                    </Flex>
                    <Flex gap={2} alignItems="center">
                      <Text.Caption fontSize={3}>
                        <T project="enterprise-dashboard">{"Permissions"}</T>
                      </Text.Caption>
                      <Table.Grid.Record.Cell cell={cells.permissions} />
                    </Flex>
                  </Flex>
                </Flex>
              </Table.Grid.Record>);

        }} />

      }
    </>);

};