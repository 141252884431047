import { AppointmentCount } from '../models/AppointmentCount';
import { EnterpriseUser } from '../models/EnterpriseUser';
import { OwnerUser } from '../models/OwnerUser';
import { SubUser } from '../models/SubUser';
import { OWNER, SUBUSER, User, UserType } from '../models/User';
import { tsToDateString } from '../utils/time';
import { V1_API_BASE_URL, V1_ENTERPRISE_BASE_URL } from './api';
import axios from './axios';

interface CurrentUserResponse<T> {
  data: T;
  included: {
    id: number;
    name: string;
    createdDate: string;
    sqspOrgId: string | null;
  };
}

interface CreateUserResponse<T> {
  data: T;
}

interface ListUsersResponse<T extends User> {
  data: T[];
  included: {
    id: number;
    userId: number;
    startDate: string;
    endDate: string;
    value: number;
  }[];
}

export interface UserData {
  password: string;
  username: string;
  businessName: string;

  timezone?: string;
}

export interface UpdateUsername {
  username: string;

  currentPassword?: never;
  password?: never;
  passwordConfirmation?: never;
}

export interface UpdatePassword {
  password: string;
  passwordConfirmation: string;

  username?: never;
}

export interface ErrorResponse {
  status_code: number;
  message: string;
  error: string | Record<string, string>;
}

// Legacy only below
// TODO: remove once Phase 2 migration is complete
export const updateCurrentUser = async (userData: UpdateUsername | UpdatePassword): Promise<EnterpriseUser> => {
  const response = await axios.patch<CurrentUserResponse<EnterpriseUser>>(`${V1_API_BASE_URL}/me`, userData);

  const { data, included } = response.data;
  return {
    ...data,
    lastActive: tsToDateString(data.lastActive),
    enterprise: included,
  };
};

export const createUser = async (enterpriseId: number, userData: UserData): Promise<OwnerUser> => {
  const response = await axios.post<CreateUserResponse<OwnerUser>>(
    `${V1_ENTERPRISE_BASE_URL}/${enterpriseId}/users`,
    userData,
  );

  const user = {
    ...response.data.data,
    lastActive: tsToDateString(response.data.data.lastActive),
  };

  const endDate = new Date();
  const startDate = tsToDateString(new Date().setDate(endDate.getDate() - 7));

  return {
    ...user,
    subUsers: [],
    appointmentCount: {
      id: user.id,
      userId: user.id,
      startDate,
      endDate: tsToDateString(endDate),
      value: 0,
    },
  } as OwnerUser;
};

const listUsers = async <T extends User>(
  enterpriseId: number,
  appointmentCount: boolean,
  parentId?: number,
  userType?: UserType,
): Promise<T[]> => {
  const type = userType ? userType : parentId ? SUBUSER : OWNER;

  const response = await axios.get<ListUsersResponse<T>>(
    // eslint-disable-next-line max-len
    `${V1_ENTERPRISE_BASE_URL}/${enterpriseId}/users?type=${type}&appointmentCount=${appointmentCount}&parentId=${parentId}`,
  );

  const { data, included } = response.data;

  if (appointmentCount) {
    const appointmentCounts = included.reduce(
      (counts: Record<number, AppointmentCount>, { id, userId, startDate, endDate, value }) => {
        counts[id] = {
          id,
          userId,
          startDate: tsToDateString(startDate),
          endDate: tsToDateString(endDate),
          value,
        };

        return counts;
      },
      {},
    );

    return data.map((userResponse) => ({
      ...userResponse,
      lastActive: tsToDateString(userResponse.lastActive),
      appointmentCount: appointmentCount ? appointmentCounts[userResponse.id] : 0,
    }));
  }

  return data.map((userResponse) => ({
    ...userResponse,
    lastActive: tsToDateString(userResponse.lastActive),
  }));
};

// TODO: Can we remove this once we move entirely to SQSP Users?
export const listOwnerUsers = async (enterpriseId: number): Promise<OwnerUser[]> => {
  return listUsers<OwnerUser>(enterpriseId, false);
};

export const impersonateUser = async (enterpriseId: number, userId: number): Promise<void> =>
  await axios.post(`${V1_ENTERPRISE_BASE_URL}/${enterpriseId}/users/${userId}/impersonate`);

export const listSubUsers = async (enterpriseId: number, parentId: number): Promise<SubUser[]> => {
  return listUsers<SubUser>(enterpriseId, false, parentId);
};
