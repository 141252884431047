import { EnterpriseUser } from '../models/EnterpriseUser';
import { SqspUser } from '../models/SqspUser';
import { V2_API_BASE_URL } from './api';
import axios from './axios';

interface CurrentUserResponse<T> {
  data: T;
  included: {
    id: number;
    name: string;
    createdDate: string;
    sqspOrgId: string | null;
  };
}

interface CreateUserResponse<T> {
  data: T;
}

export const getCurrentUser = async (): Promise<EnterpriseUser | SqspUser> => {
  const response = await axios.get<CurrentUserResponse<EnterpriseUser | SqspUser>>(`${V2_API_BASE_URL}/me`);

  const { data, included } = response.data;
  const resp = {
    ...data,
    enterprise: included,
  };
  return resp;
};

export const getUserFeatureFlags = async (flags: string[]) => {
  const response = await axios.post(`${V2_API_BASE_URL}/me/feature-flags`, {
    flags,
  });
  return response.data.flags;
};

export const logoutUserInSqspEnterprise = async (): Promise<void> => {
  const response = await axios.delete(`${V2_API_BASE_URL}/logout`);

  return response.data;
};
