import { AppointmentExport } from '../models/AppointmentExport';
import { V2_ENTERPRISE_BASE_URL } from './api';
import axios from './axios';

export const exportAppointments = async (
  enterpriseId: number,
  userIds: number[],
  includeCanceled: boolean,
  startDate: number,
  endDate: number | null,
): Promise<AppointmentExport> => {
  const response = await axios.post<{ data: AppointmentExport }>(
    `${V2_ENTERPRISE_BASE_URL}/${enterpriseId}/appointments/exports`,
    {
      userIds,
      // temporarily matching the php code: https://github.com/sqsp/scheduling-acuity/blob/a83e5b2c00e4aff3ca9df9213ba9619e7bcc66ab/libs/API/Enterprise/EnterpriseController.php#L479
      includeCanceled: includeCanceled ? '1' : '0',
      startDate: new Date(startDate).toISOString(),
      endDate: endDate ? new Date(endDate).toISOString() : null,
    },
  );
  return response.data.data;
};

export const retrieveExport = async (enterpriseId: number, exportId: number) => {
  const response = await axios.get<{ data: AppointmentExport }>(
    `${V2_ENTERPRISE_BASE_URL}/${enterpriseId}/appointments/exports/${exportId}`,
  );

  return response.data.data;
};
