import { TextInput, type TextFieldProps } from '@sqs/rosetta-elements';
import { Box, Flex, Text } from '@sqs/rosetta-primitives';
import React, { forwardRef } from 'react';

// ripped from https://github.com/sqsp/core-components/blob/master/packages/elements/src/components/TextField/TextFieldFloating.tsx
export const AcuityTextField: React.ForwardRefExoticComponent<
Omit<TextFieldProps, 'appearance'> & React.RefAttributes<HTMLDivElement>
> = forwardRef(
  // eslint-disable-next-line complexity
  (
    {
      error = false,
      errorText,
      interiorPost,
      helperText,
      interiorPre,
      label,
      labelPlacement = 'top',
      successText,
      sx,
      inputProps,
      ...rest
    },
    ref,
  ) => {
    const inputId = React.useMemo(() => inputProps?.id ?? `id${Math.random()}`, [inputProps?.id]);
    return (
      <Box
        ref={ref}
        sx={{
          cursor: inputProps?.disabled ? 'not-allowed' : 'text',
          ...sx,
        }}
        {...rest}
      >
        {labelPlacement === 'top' && (
          <Text.Label
            color="gray.300"
            htmlFor={inputId}
            lineHeight="1.5"
            pb={1}
            sx={{ cursor: inputProps?.disabled ? 'not-allowed' : 'text' }}
          >
            {label}
          </Text.Label>
        )}
        <Flex
          alignItems="center"
          backgroundColor="bg.base"
          justifyContent="space-between"
          mb={1}
          minHeight="sizes.250"
          p={2}
          sx={{
            outline: '1px solid',
            outlineColor: 'gray.700',
            borderRadius: '4px',
            ...((error || errorText) && {
              outline: '1px solid',
              outlineColor: 'red.300',
            }),
            '&:focus-within': {
              outline: '1px solid',
              outlineColor: 'gray.100',
            },
            lineHeight: 1,
          }}
        >
          {interiorPre && <Box pr={1}>{interiorPre}</Box>}
          {labelPlacement === 'inline' && (
            <Text.Body
              as="label"
              color="gray.100"
              htmlFor={inputId}
              maxWidth="max-content"
              mr={1}
              sx={{ cursor: inputProps?.disabled ? 'not-allowed' : 'text' }}
              width="100%"
            >
              {label}
            </Text.Body>
          )}
          <TextInput
            aria-describedby={'desc-' + inputId}
            aria-invalid={error || !!errorText}
            id={inputId}
            px={0}
            sx={{
              '&:focus': {
                outline: 'none',
              },
            }}
            textAlign={labelPlacement === 'inline' ? 'right' : 'left'}
            width="100%"
            {...inputProps}
          />
          <>{interiorPost && <Box ml={1}>{interiorPost}</Box>}</>
        </Flex>

        <Flex flexDirection="column" id={'desc-' + inputId}>
          {errorText ? (
            <Text.Caption color="red.300">
              <>{errorText}</>
            </Text.Caption>
          ) : null}
          {successText ? (
            <Text.Caption color="green.300">
              <>{successText}</>
            </Text.Caption>
          ) : null}
          {helperText ? (
            <Text.Caption>
              <>{helperText}</>
            </Text.Caption>
          ) : null}
        </Flex>
      </Box>
    );
  },
);
