import { Table } from '@sqs/rosetta-experimental';
import { Box } from '@sqs/rosetta-primitives';
import throttle from 'lodash/throttle';
import React, { useEffect } from 'react';
import {
  INFINITE_SCROLL_LOAD_THRESHOLD,
  PAGINATION_LIMIT_MAX,
  PAGINATION_LIMIT_MID,
  PAGINATION_LIMIT_MIN,
} from '../../../const/pagination';
import { usePlatformBreakpoint } from '../../../hooks/usePlatformBreakpoint';
import { Breakpoint } from '@sqs/rosetta-utilities';

let scrollTrack = 1;

export const TablePagination = () => {
  const { isMobile } = usePlatformBreakpoint();
  const table = Table.useTable();

  if (!Object.keys(table).length) {
    throw new Error('TablePagination has to be used within <Table>');
  }

  const pageSize = table.getState().pagination.pageSize;

  // if on mobile
  // add scroll listener to detect when user is X pixels from the bottom of the viewable page.
  // if they hit the threshold, increase the pageSize by Y
  // this should refresh immediately because all of the data lives in the store
  useEffect(() => {
    if (isMobile) {
      window.addEventListener(
        'scroll',
        throttle((e) => {
          const distanceToBottomOfPage = document.body.scrollHeight - window.innerHeight - window.scrollY;
          if (distanceToBottomOfPage < INFINITE_SCROLL_LOAD_THRESHOLD && table.getCanNextPage()) {
            scrollTrack += 1;
            table.setPageSize(pageSize * scrollTrack);
          }
        }, 100),
      );
    }
  }, []);

  return (
    <Breakpoint.Provider>
      <Breakpoint.Renderer
        render={{
          default: () => (
            <Table.Controls>
              <Table.Controls.Right>
                <Table.Pagination.PageCount
                  options={[PAGINATION_LIMIT_MIN, PAGINATION_LIMIT_MID, PAGINATION_LIMIT_MAX]}
                />
                <Table.Pagination>
                  <Table.Pagination.PageLabel />
                </Table.Pagination>
              </Table.Controls.Right>
            </Table.Controls>
          ),
          'mobile-0': () => <Box p={6} />,
        }}
      />
    </Breakpoint.Provider>
  );
};
