import { Modal } from '@sqs/rosetta-elements';
import { Search } from '@sqs/rosetta-icons';
import { Box, Button } from '@sqs/rosetta-primitives';
import React, { useEffect, useState } from 'react';
import { t } from '../../i18n';
import { Enterprise } from '../../models/Enterprise';
import { SchedulingInstance } from '../../models/SchedulingInstance';
import { ORG_MANAGER, SqspUser } from '../../models/SqspUser';
import { selectEnterprise } from '../../stores/currentUser';
import { RootState } from '../../stores/rootReducer';
import { fetchSchedulingInstances, selectAll } from '../../stores/schedulingInstances';
import { useAppDispatch, useAppSelector } from '../../stores/store';
import { CreateAccount } from '../CreateAccount';
import { PageHeader } from '../PageHeader';
import { DeleteSchedulingInstanceModal } from './DeleteSchedulingInstanceModal';
import { SchedulingInstancesListResults } from './SchedulingInstancesListResults';

export const SchedulingInstancesList = () => {
  const dispatch = useAppDispatch();
  const [showDeleteInstanceModal, setShowDeleteInstanceModal] = useState<SchedulingInstance | null>(null);
  const [showSearch, setShowSearch] = useState(false);

  const { currentUser, enterprise, hasLoaded, schedulingInstances, pageInfo } = useAppSelector((state: RootState) => ({
    enterprise: selectEnterprise(state.currentUser) as Enterprise,
    hasLoaded: !state.schedulingInstances.isLoading,
    schedulingInstances: selectAll(state.schedulingInstances),
    currentUser: state.currentUser.currentUser as SqspUser,
    pageInfo: state.schedulingInstances.pageInfo
  }));

  useEffect(() => {
    if (!enterprise) {
      throw new Error('User is not connected to an Enterprise');
    }
    if (!hasLoaded) {
      dispatch(fetchSchedulingInstances(enterprise.id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openDeleteModal = (schedulingInstance: SchedulingInstance) => {
    setShowDeleteInstanceModal(schedulingInstance);
  };
  const closeDeleteModal = () => {
    setShowDeleteInstanceModal(null);
  };

  const mobileSearchButton = () => {
    return (
      <Button.Tertiary type="button" onClick={() => setShowSearch(!showSearch)}>
        <Search />
      </Button.Tertiary>);

  };

  const desktopActionButton = currentUser.role === ORG_MANAGER ? <CreateAccount /> : undefined;
  const mobileActions = [mobileSearchButton()];
  if (currentUser.role === ORG_MANAGER) {
    mobileActions.push(<CreateAccount />);
  }

  return (
    <Box>
      <PageHeader
        actionButton={desktopActionButton}
        mobileActions={mobileActions}
        title={t("Scheduling Instances", {}, { project: 'enterprise-dashboard' })} />

      <SchedulingInstancesListResults
        schedulingInstances={schedulingInstances}
        hasLoaded={hasLoaded}
        openDeleteModal={openDeleteModal}
        pageInfo={pageInfo}
        showSearch={showSearch}
        currentUser={currentUser} />

      <Modal.Transition>
        {showDeleteInstanceModal &&
        <DeleteSchedulingInstanceModal schedulingInstance={showDeleteInstanceModal} closeModal={closeDeleteModal} />
        }
      </Modal.Transition>
    </Box>);

};