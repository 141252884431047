import { Plus } from '@sqs/rosetta-icons';
import { Button } from '@sqs/rosetta-primitives';
import React from 'react';
import { T } from '../../i18n';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';

interface CreateUserButtonProps {
  readonly onClick: () => void;
  readonly showFullButton?: boolean;
  readonly disabled?: boolean;
}

export const CreateUserButton = ({ onClick, showFullButton, disabled }: CreateUserButtonProps) => {
  const { isMobile } = usePlatformBreakpoint();

  if (showFullButton || !isMobile) {
    return (
      <Button.Primary size="large" type="button" onClick={onClick} disabled={disabled}>
        <Plus color="white" />
        &nbsp;
        <T project="enterprise-dashboard">{"Invite User"}</T>
      </Button.Primary>);

  }
  return (
    <Button.Tertiary type="button" onClick={onClick} size="small" disabled={disabled}>
      <Plus />
    </Button.Tertiary>);

};