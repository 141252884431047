import { ActionBar } from '@sqs/rosetta-compositions';
import { Checkbox } from '@sqs/rosetta-elements';
import { ColumnDef, Table } from '@sqs/rosetta-experimental';
import { Box, Flex, Text } from '@sqs/rosetta-primitives';
import { space } from '@sqs/rosetta-tokens';
import React, { useEffect, useMemo, useState } from 'react';
import { PAGINATION_LIMIT_MAX, PAGINATION_LIMIT_MID, PAGINATION_LIMIT_MIN } from '../../const/pagination';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { T, t } from '../../i18n';
import { AppointmentCount } from '../../models/AppointmentCount';
import { Enterprise } from '../../models/Enterprise';
import { OwnerUser } from '../../models/OwnerUser';
import { selectEnterprise } from '../../stores/currentUser';
import { fetchOwnerUsers, selectAll } from '../../stores/ownerUsers';
import { RootState } from '../../stores/rootReducer';
import {
  allOwnerUsersDeselected,
  allOwnerUsersSelected,
  ownerUserDeselected,
  ownerUserSelected } from
'../../stores/selectedOwnerUsers';
import { useAppDispatch, useAppSelector } from '../../stores/store';
import { AppointmentExportModal } from '../AppointmentExportModal';
import { ImpersonateLink } from '../common';
import { AcuityLoader } from '../common/AcuityLoader';
import { TableHeader } from '../common/Table/TableHeader';
import { CreateAccount } from '../CreateAccount';
import { PageHeader } from '../PageHeader';
import { SubUsersList } from '../SubUsersList';

interface Row {
  original?: OwnerUser;
}

const getAppointmentCount = (appointmentCount?: AppointmentCount): number =>
typeof appointmentCount !== 'undefined' ? appointmentCount.value : 0;

export function OwnerUsersList() {
  const [isExportModalOpen, setExportModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { isMobile } = usePlatformBreakpoint();

  const { enterprise, hasLoaded, ownerUsers, selectedOwnerUsers } = useAppSelector((state: RootState) => ({
    enterprise: selectEnterprise(state.currentUser) as Enterprise,
    hasLoaded: !state.ownerUsers.isLoading,
    ownerUsers: selectAll(state.ownerUsers),
    selectedOwnerUsers: state.selectedOwnerUsers
  }));

  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    if (!enterprise) {
      throw new Error('User is not connected to an Enterprise');
    }

    dispatch(fetchOwnerUsers(enterprise.id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectAllString = t("include all visible in table", {}, { project: 'enterprise-dashboard' });
  const deSelectAllString = t("un-select all visible in table", {}, { project: 'enterprise-dashboard' });

  const columns = useMemo<ColumnDef<OwnerUser>[]>(
    () => [
    {
      accessorKey: 'id',
      header: () =>
      !isMobile ?
      <Checkbox
        checked={allChecked && selectedOwnerUsers.length > 0}
        value={'all'}
        aria-label={allChecked ? deSelectAllString : selectAllString}
        onChange={(val: boolean) => {
          setAllChecked(val);
          if (val) {
            dispatch(allOwnerUsersSelected(ownerUsers));
          } else {
            dispatch(allOwnerUsersDeselected(null));
          }
        }} /> :


      <></>,

      cell: (cell) => {
        return (
          <Checkbox
            checked={selectedOwnerUsers.indexOf(cell.getValue()) !== -1}
            aria-label={`select ${cell.row.original.businessName}`}
            onChange={(val: boolean) => {
              if (val) {
                dispatch(ownerUserSelected(cell.getValue()));
              } else {
                dispatch(ownerUserDeselected(cell.getValue()));
              }

              if (selectedOwnerUsers.length === 0) {
                setAllChecked(false);
              }
            }}
            value={cell.getValue()} />);


      }
    },
    {
      accessorKey: 'businessName',
      header: ({ column }: any) =>
      <TableHeader label={t("Name", null, { project: 'enterprise-dashboard' })} column={column} />

    },
    {
      accessorKey: 'username',
      sortingFn: 'alphanumeric',
      header: ({ column }: any) =>
      <TableHeader label={t("Email Address", null, { project: 'enterprise-dashboard' })} column={column} />,

      cell: (cell) =>
      <Text.Body sx={{ wordBreak: 'break-word' }}>
            <>{cell.getValue()}</>
          </Text.Body>

    },
    {
      accessorKey: 'subUserCount',
      header: ({ column }: any) =>
      <TableHeader label={t("Users", null, { project: 'enterprise-dashboard' })} column={column} />,

      cell: (cell) => {
        const ownerUser = cell.row.original as OwnerUser;
        return ownerUser.subUserCount ?
        <SubUsersList
          subUsersCount={ownerUser.subUserCount}
          ownerId={ownerUser.id}
          ownerEmail={ownerUser.username} /> :


        <Text.Body>
              <>0</>
            </Text.Body>;

      }
    },
    {
      accessorKey: 'lastActive',
      sortingFn: 'datetime',
      header: ({ column }: any) =>
      <TableHeader label={t("Last Active", null, { project: 'enterprise-dashboard' })} column={column} />

    },
    {
      accessorKey: 'login',
      enableSorting: false,
      header: t("Login", null, { project: 'enterprise-dashboard' }),
      cell: (cell) => {
        const ownerUser = cell.row.original as OwnerUser;
        return <ImpersonateLink enterpriseId={enterprise.id} userId={ownerUser.id} />;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    [selectedOwnerUsers, ownerUsers, allChecked, deSelectAllString, selectAllString, dispatch, enterprise]
  );

  if (!hasLoaded) {
    return (
      <Flex height="100%" justifyContent="center">
        <AcuityLoader />
      </Flex>);

  }

  if (!ownerUsers.length) {
    return (
      <Box p={space[10]}>
        <Flex justifyContent="center">
          <Box mb={space[6]}>
            <Text.Subtitle>
              <T project="enterprise-dashboard">{"No Accounts Found"}</T>
            </Text.Subtitle>
          </Box>
        </Flex>
        <Flex justifyContent="center">
          <CreateAccount />
        </Flex>
      </Box>);

  }

  return (
    <>
      <PageHeader
        actionButton={<CreateAccount />}
        title={t("Scheduling Instances", {}, { project: 'enterprise-dashboard' })} />

      <Box mx={-6} mb={6}>
        {/* negative margin needed to remove table padding which is not configurable via props */}
        <ActionBar.AutoSizing.Provider>
          <ActionBar.AutoSizing.Target>
            <Table
              columns={columns}
              data={ownerUsers}
              enablePagination
              enableSort
              initialState={{
                sorting: [
                {
                  id: 'businessName',
                  desc: false
                }],

                pagination: {
                  pageIndex: 0,
                  pageSize: PAGINATION_LIMIT_MIN
                }
              }}>

              {isMobile &&
              <>
                  <Table.Grid />
                </>
              }
              {!isMobile &&
              <>
                  <Table.List />
                </>
              }
              <Table.Controls>
                <Table.Controls.Right>
                  <Table.Pagination.PageCount
                    options={[PAGINATION_LIMIT_MIN, PAGINATION_LIMIT_MID, PAGINATION_LIMIT_MAX]} />

                  <Table.Pagination>
                    <Table.Pagination.PageLabel />
                  </Table.Pagination>
                </Table.Controls.Right>
              </Table.Controls>
            </Table>
          </ActionBar.AutoSizing.Target>
          <ActionBar.AnimatedContainer itemSelectedNumber={selectedOwnerUsers.length}>
            <ActionBar.AutoSizing.Container>
              <ActionBar
                actions={[
                {
                  label: t("Export To Spreadsheet", {}, { project: 'enterprise-dashboard' }),
                  onClick: () => setExportModalOpen(true),
                  preserveSelection: true
                }]
                }
                itemSelectedNumber={selectedOwnerUsers.length}
                maxItemsNumber={ownerUsers.length}
                onDeselectItems={() => dispatch(allOwnerUsersDeselected(null))}
                onSelectAllItems={() => dispatch(allOwnerUsersSelected(ownerUsers))} />

              <AppointmentExportModal
                isOpen={isExportModalOpen}
                onClose={() => setExportModalOpen(false)}
                onSubmit={() => dispatch(allOwnerUsersDeselected(null))} />

            </ActionBar.AutoSizing.Container>
          </ActionBar.AnimatedContainer>
        </ActionBar.AutoSizing.Provider>
      </Box>
    </>);

}