import { Column } from '@sqs/rosetta-experimental';
import { ArrowSmallDown, ArrowUp } from '@sqs/rosetta-glyphs';
import { Flex, Text } from '@sqs/rosetta-primitives';
import React from 'react';
import { usePlatformBreakpoint } from '../../../hooks/usePlatformBreakpoint';
import { TooltipIcon } from './TooltipIcon';

interface TableHeaderProps {
  readonly column: Column<any, string>;
  readonly label: string;
  readonly tooltip?: string | React.ReactNode;
}

export const TableHeader = ({ column, label, tooltip }: TableHeaderProps) => {
  const { isMobile } = usePlatformBreakpoint();

  const showTooltip = tooltip && !isMobile;

  const getSortIcon = () => {
    if (isMobile) {
      return null;
    }
    if (column.getIsSorted()) {
      if (column.getIsSorted() === 'desc') {
        return <ArrowUp />;
      }
      return <ArrowSmallDown />;
    }
    return null;
  };

  if (!(column.id && column.getCanSort())) {
    return (
      <Text.Label>
        <Flex justifyContent="flex-start" alignItems="center" gap={'3px'}>
          {label}
          {showTooltip ? <TooltipIcon id={column.id} tooltipContent={tooltip} /> : null}
        </Flex>
      </Text.Label>
    );
  }

  return (
    <Text.Label>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          if (!isMobile && column.id) {
            column.toggleSorting();
          }
        }}
      >
        <Flex justifyContent="flex-start" alignItems="center" gap={'3px'}>
          {label}
          {showTooltip ? <TooltipIcon id={column.id} tooltipContent={tooltip} /> : null}
          {getSortIcon()}
        </Flex>
      </a>
    </Text.Label>
  );
};
