export const ROUTE_HOME = '';
export const ROUTE_DASHBOARD = 'dashboard';

export const ROUTE_USERS = 'users';
export const ROUTE_INVITED = 'invited';
export const ROUTE_VIEW = 'view/:id';

export const ROUTE_REPORTING = 'reporting';

export const ROUTE_SETTINGS = 'settings';
export const ROUTE_ORGANIZATION = 'organization';
export const ROUTE_API_KEY = 'apiKey';
export const ROUTE_SSO = 'sso';

export const NAV_HOME = `/${ROUTE_HOME}`;
export const NAV_DASHBOARD = `/${ROUTE_DASHBOARD}`;

export const NAV_USERS = `/${ROUTE_USERS}`;
export const NAV_USERS_INVITED = `/${ROUTE_USERS}/${ROUTE_INVITED}`;
export const NAV_USER_VIEW = (id: number) => `/${ROUTE_USERS}/${ROUTE_VIEW}`.replace(/:id/g, `${id}`);

export const NAV_REPORTING = `/${ROUTE_REPORTING}`;

export const NAV_SETTINGS = `/${ROUTE_SETTINGS}`;
export const NAV_SETTINGS_ORGANIZATION = `/${ROUTE_SETTINGS}/${ROUTE_ORGANIZATION}`;
export const NAV_SETTINGS_API_KEY = `/${ROUTE_SETTINGS}/${ROUTE_API_KEY}`;
export const NAV_SETTINGS_SSO = `/${ROUTE_SETTINGS}/${ROUTE_SSO}`;
