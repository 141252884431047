import { ActionList } from '@sqs/rosetta-compositions';
import React from 'react';
import { resendInviteToUser } from '../../apis/sqspUsers';
import { sendErrorMessage, sendSuccessMessage } from '../../stores/messages';
import { useAppDispatch } from '../../stores/store';
import { t, T } from '../../i18n';

interface ResendInvitationActionItemProps {
  readonly inviteCode: string | null;
  readonly enterpriseId: number;
  readonly onRequestClose: () => void;
}
export const UserResendInvitationActionItem = ({
  inviteCode,
  enterpriseId,
  onRequestClose
}: ResendInvitationActionItemProps) => {
  const dispatch = useAppDispatch();

  const onClick = () => {
    resendInviteToUser(enterpriseId, inviteCode).
    then(() => {
      dispatch(sendSuccessMessage(t("Invitation was emailed to user.", {}, { project: 'enterprise-dashboard' })));
    }).
    catch(() => {
      dispatch(
        sendErrorMessage(t("There was a problem sending the invite. Please try again.",
        {}, { project: 'enterprise-dashboard' })
        )
      );
    }).
    finally(onRequestClose);
  };

  return (
    <ActionList.Item as="div" onClick={onClick}>
      <T project="enterprise-dashboard">{"Resend Invitation"}</T>
    </ActionList.Item>);

};