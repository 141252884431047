import { ExternalLink } from '@sqs/rosetta-icons';
import React, { ReactElement } from 'react';
import { impersonateUser } from '../../apis/EnterpriseApiV1';

export function ImpersonateLink({
  enterpriseId,
  userId,
  location,
}: {
  readonly enterpriseId: number;
  readonly userId: number;
  readonly location?: string;
}): ReactElement {
  const path = location || 'appointments.php';
  return (
    <ExternalLink
      onClick={async () => {
        try {
          await impersonateUser(enterpriseId, userId);
          window.location.assign(`${__SCHEDULING_HOST__}/${path}`);
        } catch (e) {
          //we should show some kind of error here, maybe a toast once those are added
        }
      }}
      sx={{ cursor: 'pointer' }}
    />
  );
}
