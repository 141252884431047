import { TextLink } from '@sqs/rosetta-elements';
import { ColumnDef, Table } from '@sqs/rosetta-experimental';
import { Box, Flex, Text } from '@sqs/rosetta-primitives';
import React from 'react';
import { PAGINATION_LIMIT_MIN } from '../../const/pagination';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { useSchedulingInstanceActions } from '../../hooks/useSchedulingInstanceActions';
import { t } from '../../i18n';
import { PageInfo } from '../../models/PaginatedResponse';
import { SchedulingInstance } from '../../models/SchedulingInstance';
import { OrgUser } from '../../models/SqspUser';
import { tsToDateString } from '../../utils/time';
import { AcuityLoader } from '../common/AcuityLoader';
import { PermissionsMap } from '../common/PermissionsMap';
import { TableHeader } from '../common/Table/TableHeader';
import { TablePagination } from '../common/Table/TablePagination';
import { SchedulingInstanceListActionMenu } from './SchedulingInstanceListActionMenu';
import { SchedulingInstancesListResultsTableData } from './SchedulingInstancesListResultsTableData';

interface SchedulingInstancesListResultsProps {
  readonly schedulingInstances: SchedulingInstance[];
  readonly hasLoaded: boolean;
  readonly openDeleteModal: (schedulingInstance: SchedulingInstance) => void;
  readonly pageInfo: PageInfo;
  readonly showSearch: boolean;
  readonly currentUser: OrgUser;
}

export const SchedulingInstancesListResults = ({
  schedulingInstances,
  hasLoaded,
  openDeleteModal,
  showSearch,
  currentUser
}: SchedulingInstancesListResultsProps) => {
  const { isMobile } = usePlatformBreakpoint();
  const { viewSchedulingInstanceAsUser } = useSchedulingInstanceActions();

  const columns: ColumnDef<SchedulingInstance, any>[] = [
  {
    accessorKey: 'instanceName',
    sortingFn: 'alphanumeric',
    header: ({ column }) => {
      return <TableHeader label={t("Name", null, { project: 'enterprise-dashboard' })} column={column} />;
    },
    cell: (cell) => {
      return (
        <Text.Body m={0}>
            <TextLink
            css={{
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
            onClick={() => viewSchedulingInstanceAsUser(cell.row.original as SchedulingInstance)}>

              {cell.getValue()}
            </TextLink>
          </Text.Body>);

    }
  },
  {
    accessorKey: 'calendarCount',
    sortingFn: 'alphanumeric',
    header: ({ column }) => {
      return <TableHeader label={t("# of calendars", null, { project: 'enterprise-dashboard' })} column={column} />;
    }
  },
  {
    accessorKey: 'userCount',
    sortingFn: 'alphanumeric',
    header: ({ column }) => {
      return <TableHeader label={t("# of users", null, { project: 'enterprise-dashboard' })} column={column} />;
    }
  },
  {
    accessorKey: 'permissions',
    enableSorting: false,
    header: ({ column }) => {
      return (
        <TableHeader
          label={t("Permissions", null, { project: 'enterprise-dashboard' })}
          column={column}
          tooltip={t("The permissions that you have within a Scheduling Instance.",

          {}, {
            project: 'enterprise-dashboard' })
          } />);


    },
    cell: (cell) => {
      return <PermissionsMap permission={cell.getValue()} />;
    }
  },
  {
    accessorKey: 'dateCreated',
    sortingFn: 'datetime',
    enableHiding: isMobile,
    header: ({ column }) => {
      return <TableHeader label={t("Date created", null, { project: 'enterprise-dashboard' })} column={column} />;
    },
    cell: (cell) => {
      const dateString = tsToDateString(cell.getValue());
      if (isMobile) {
        return <Text.Caption color="black">{dateString}</Text.Caption>;
      }
      return <Text.Body m={0}>{dateString}</Text.Body>;
    }
  },
  {
    accessorKey: 'id',
    enableSorting: false,
    header: ({ column }) => {
      return <TableHeader label={t("Actions", null, { project: 'enterprise-dashboard' })} column={column} />;
    },
    cell: (cell) =>
    <SchedulingInstanceListActionMenu
      openDeleteModal={openDeleteModal}
      schedulingInstance={cell.row.original as SchedulingInstance} />


  }];


  if (!hasLoaded && !schedulingInstances.length) {
    return (
      <Flex height="300px" justifyContent="center" alignItems="center" flexDirection="column">
        <AcuityLoader />
      </Flex>);

  }

  const shouldShowSearch = !isMobile || showSearch;

  return (
    <Box mb={5} position="relative">
      <Table
        columns={columns}
        data={schedulingInstances}
        enableSort
        enablePagination
        enableSearch={shouldShowSearch}
        initialState={{
          sorting: [
          {
            id: 'instanceName',
            desc: false
          }],

          pagination: {
            pageIndex: 0,
            pageSize: PAGINATION_LIMIT_MIN
          }
        }}>

        {shouldShowSearch &&
        <Table.Controls>
            <Table.Controls.Left sx={{ marginBottom: 5 }}>
              <Table.Search isOpen={shouldShowSearch} />
              {!isMobile && <Table.RecordCount />}
            </Table.Controls.Left>
          </Table.Controls>
        }
        <SchedulingInstancesListResultsTableData currentUser={currentUser} />
        <TablePagination />
      </Table>
    </Box>);

};