import { Modal, TextLink } from '@sqs/rosetta-elements';
import { Button } from '@sqs/rosetta-primitives';
import React, { useState } from 'react';
import { T, t } from '../../../i18n';
import { EmptyResults } from '../../common/EmptyResults';
import { EnterpriseSsoAddModal } from './EnterpriseSsoAddModal';

export const EnterpriseSso = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const emptyResultSubtitle = () => {
    return (
      <>
        <T project="enterprise-dashboard">{"Single sign-on (SSO) is an easy and secure method of managing user access to your Enterprise organization."}</T>


        &nbsp;
        <TextLink href="#">
          <T project="enterprise-dashboard">{"Learn more"}</T>
        </TextLink>
      </>);

  };

  return (
    <>
      <EmptyResults
        noImage
        title={t("You currently don\u2019t have SSO set up", {}, { project: 'enterprise-dashboard' })}
        subtitle={emptyResultSubtitle()}
        actionButton={
        <Button.Primary size="large" type="button" onClick={() => setIsAddModalOpen(true)}>
            Setup SSO
          </Button.Primary>
        } />

      <Modal.Transition>
        {isAddModalOpen && <EnterpriseSsoAddModal closeModal={() => setIsAddModalOpen(false)} />}
      </Modal.Transition>
    </>);

};