import React, { useState } from 'react';
import { CreateUserButton } from './CreateUserButton';
import { CreateUserModal } from './CreateUserModal';

interface CreateUserProps {
  readonly showFullButton?: boolean;
  readonly disabled?: boolean;
}

export const CreateUser = ({ showFullButton = false, disabled = false }: CreateUserProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <CreateUserButton showFullButton={showFullButton} onClick={() => setIsModalOpen(true)} disabled={disabled} />
      <CreateUserModal isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />
    </>
  );
};
