import { Text } from '@sqs/rosetta-primitives';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { EnterpriseUser } from '../../models/EnterpriseUser';
import { updateCurrentUser } from '../../stores/currentUser';
import { sendErrorMessage, sendSuccessMessage } from '../../stores/messages';
import { RootState } from '../../stores/rootReducer';
import { useAppDispatch, useAppSelector } from '../../stores/store';
import { t, T } from '../../i18n';
import { AcuityTextFieldController } from '../common/AcuityTextFieldController';

interface UsernameFormProps {
  readonly setFormIsDirty: (v: boolean) => void;
  readonly triggerReset: boolean;
  readonly setTriggerReset: (v: boolean) => void;
}

interface UsernameFormData {
  username: string;
}

export const UsernameForm = React.forwardRef<HTMLFormElement, UsernameFormProps>(
  ({ setFormIsDirty, triggerReset, setTriggerReset }, ref) => {
    const dispatch = useAppDispatch();

    const {
      currentUser: { username: initialUsername },
      error,
      isLoading
    } = useAppSelector((state: RootState) => ({
      currentUser: state.currentUser.currentUser as EnterpriseUser,
      error: state.currentUser.error,
      isLoading: state.currentUser.isLoading
    }));

    const {
      control,
      formState: { errors, isDirty },
      handleSubmit,
      register,
      reset,
      watch
    } = useForm<UsernameFormData>({
      defaultValues: useMemo(
        () => ({
          username: initialUsername
        }),
        [initialUsername]
      )
    });

    useEffect(() => {
      setFormIsDirty(isDirty);
    }, [isDirty]);

    useEffect(() => {
      if (triggerReset) {
        reset();
        setTriggerReset(false);
      }
    }, [triggerReset]);

    // updates default value of username after a save is made
    useEffect(() => {
      reset({ username: initialUsername });
    }, [initialUsername]);

    const submitUsernameForm = handleSubmit((formData) => {
      try {
        dispatch(
          updateCurrentUser(
            {
              username: formData.username
            }, t("Your email was successfully changed.",
            null, { project: 'enterprise-dashboard' })
          )
        );
      } catch (err) {
        dispatch(
          sendErrorMessage(t("There was an error changing your email address. Please try again.",
          null, {
            project: 'enterprise-dashboard' })

          )
        );
      }
    });
    return (
      <form onSubmit={submitUsernameForm} ref={ref}>
        <Text.Body as="label">
          <T project="enterprise-dashboard">{"Email / Username"}</T>
          <AcuityTextFieldController
            name="username"
            control={control}
            rules={{
              value: initialUsername,
              required: t("Email is required", {}, { project: 'enterprise-dashboard' }),
              pattern: {
                message: t("Email is invalid", {}, { project: 'enterprise-dashboard' }),
                value: /\S+@\S+\.\S+/
              }
            }} />

        </Text.Body>
      </form>);

  }
);