import { Banner, Dropdown } from '@sqs/rosetta-compositions';
import { Text } from '@sqs/rosetta-primitives';
import { useTheme } from '@sqs/rosetta-styled';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { t, T } from '../../i18n';
import {
  ORG_MEMBER,
  ORG_MANAGER,
  SqspUserType,
  ORG_MEMBER_CANONICAL,
  ORG_MANAGER_CANONICAL } from
'../../models/SqspUser';

interface UserRoleDropdownProps {
  readonly currentRole?: SqspUserType;
}

export const UserRoleDropdown = ({ currentRole }: UserRoleDropdownProps) => {
  const { fontSizes } = useTheme();
  const {
    clearErrors,
    formState: { errors },
    register,
    setValue,
    watch
  } = useFormContext();

  const userRole = watch('orgRole');
  register('orgRole', { required: t("Please choose a role for this user", {}, { project: 'enterprise-dashboard' }) });

  return (
    <>
      <Dropdown
        id="user-role"
        aria-labelledby="role-text"
        placement="bottom"
        onChange={(val: string) => {
          setValue('orgRole', val, { shouldDirty: true });
          clearErrors('orgRole');
        }}
        value={watch('orgRole')}>

        <Dropdown.Option
          value={ORG_MEMBER}
          description={
          <Text.Caption>
              <T project="enterprise-dashboard">{"Org members have limited access to the organization. They can be added to any scheduling instances with unique permission levels."}</T>



            </Text.Caption>
          }>

          <Text.Body m={0} fontSize={fontSizes[3]} fontWeight={500}>
            {ORG_MEMBER_CANONICAL}
          </Text.Body>
        </Dropdown.Option>
        <Dropdown.Option
          value={ORG_MANAGER}
          description={
          <Text.Caption>
              <T project="enterprise-dashboard">{"Managers have full access to the organization, which includes all scheduling instances, user management, and organization wide settings."}</T>



            </Text.Caption>
          }>

          <Text.Body m={0} fontSize={fontSizes[3]} fontWeight={500}>
            {ORG_MANAGER_CANONICAL}
          </Text.Body>
        </Dropdown.Option>
      </Dropdown>
      {errors.orgRole &&
      <Text.Label color="red.400" role="alert">
          <>{errors.orgRole.message}</>
        </Text.Label>
      }
      {currentRole === ORG_MEMBER && userRole === ORG_MANAGER &&
      <Banner
        mt={5}
        variant="info"
        icon={<></>}
        title={t("You are making this user a Manager", {}, { project: 'enterprise-dashboard' })}
        body={t("Org managers have access to all scheduling instances and management settings for the organization.",

        {}, {
          project: 'enterprise-dashboard' })
        } />

      }
      {currentRole === ORG_MANAGER && userRole === ORG_MEMBER &&
      <Banner
        mt={5}
        variant="error"
        icon={<></>}
        title={t("You are changing this user from a Manager", {}, { project: 'enterprise-dashboard' })}
        body={t("This user will lose access to their current scheduling instances",

        {}, {
          project: 'enterprise-dashboard' })
        } />

      }
    </>);

};