import { Text } from '@sqs/rosetta-primitives';
import React from 'react';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { T } from '../../i18n';

export const PermissionsMap = ({ permission }: {readonly permission: string;}) => {
  const { isMobile } = usePlatformBreakpoint();

  let result;
  switch (permission) {
    case 'OWNER':
      result = <T project="enterprise-dashboard">{"Manager"}</T>;
      break;
    case 'ADMIN':
      result = <T project="enterprise-dashboard">{"Admin"}</T>;
      break;
    case 'VIEW':
      result = <T project="enterprise-dashboard">{"View Only"}</T>;
      break;
    case 'EDIT':
      result = <T project="enterprise-dashboard">{"View & Edit"}</T>;
      break;
    default:
      result = <T project="enterprise-dashboard">{"Unknown"}</T>;
      break;
  }
  if (isMobile) {
    return (
      <Text.Caption m={0} lineHeight={0} fontSize={3} color="black">
        {result}
      </Text.Caption>);

  }
  return <Text.Body m={0}>{result}</Text.Body>;
};