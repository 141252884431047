import { SliceCaseReducers, createSlice, createEntityAdapter, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { OwnerUser } from '../../models/OwnerUser';
import { reduxErrorHandler } from '../../utils/reduxError';
import { AppThunk } from '../store';
import { listOwnerUsers, createUser, UserData, ErrorResponse } from '../../apis/EnterpriseApiV1';
import { AxiosError } from 'axios';
import { t } from '../../i18n';

type OwnerUsersState = {
  isLoading: boolean;
  error: null | string | Record<string, string>;
} & EntityState<OwnerUser>;

const ownerUsersAdapter = createEntityAdapter<OwnerUser>();

export const ownerUsersSlice = createSlice<OwnerUsersState, SliceCaseReducers<OwnerUsersState>, 'ownerUsers'>({
  name: 'ownerUsers',
  initialState: ownerUsersAdapter.getInitialState({
    isLoading: false,
    error: null,
    entities: null
  }),
  reducers: {
    ownerUserRequested(state) {
      state.isLoading = true;
      state.error = null;
    },
    ownerUsersRequested(state) {
      state.isLoading = true;
      state.error = null;
    },
    ownerUserReceived(state, action: PayloadAction<OwnerUser>) {
      ownerUsersAdapter.upsertOne(state, action.payload);
      state.isLoading = false;
      state.error = null;
    },
    ownerUsersReceived(state, action: PayloadAction<OwnerUser[]>) {
      ownerUsersAdapter.setAll(state, action.payload);
      state.isLoading = false;
      state.error = null;
    },
    errorReceived(state, action: PayloadAction<string | Record<string, string>>) {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});

export const { reducer: ownerUsersReducer } = ownerUsersSlice;

// Selectors
export const { selectAll, selectById, selectTotal } = ownerUsersAdapter.getSelectors();

export const { ownerUsersRequested, ownerUserRequested, ownerUsersReceived, ownerUserReceived, errorReceived } =
ownerUsersSlice.actions;

// Async actions
export const fetchOwnerUsers =
(enterpriseId: number): AppThunk =>
async (dispatch) => {
  try {
    dispatch(ownerUsersRequested(undefined));
    const ownerUsers = await listOwnerUsers(enterpriseId);

    dispatch(ownerUsersReceived(ownerUsers));
  } catch (e: any) {
    reduxErrorHandler(
      e,
      dispatch,
      errorReceived, t("Not authorized to view users",
      null, { project: 'enterprise-dashboard' })
    );
  }
};

export const createOwnerUser =
(enterpriseId: number, userData: UserData): AppThunk =>
async (dispatch) => {
  try {
    dispatch(ownerUserRequested(undefined));

    const ownerUser = await createUser(enterpriseId, userData);

    dispatch(ownerUserReceived(ownerUser));
  } catch (e: any) {
    const error: AxiosError<ErrorResponse> = e;
    if (!error.response) {
      return dispatch(
        errorReceived(t("Something went wrong. Please refresh and try again.",
        null, { project: 'enterprise-dashboard' })
        )
      );
    }
    const { data } = error.response;

    reduxErrorHandler(
      e,
      dispatch,
      errorReceived, t("Not authorized to create a user",
      null, { project: 'enterprise-dashboard' })
    );

    return Promise.reject(data.error);
  }
};