import { Modal, TextLink } from '@sqs/rosetta-elements';
import { Duplicate } from '@sqs/rosetta-icons';
import { Button, Flex, Text } from '@sqs/rosetta-primitives';
import { useTheme } from '@sqs/rosetta-styled';
import React, { useEffect, useRef, useState } from 'react';
import copyableTextStyles from '../../hooks/useCopyableText.less';
import { T, t } from '../../i18n';
import { API_KEY_LENGTH } from '../../models/ApiKey';
import { EnterpriseUser } from '../../models/EnterpriseUser';
import { fetchApiKey, resetApiKey } from '../../stores/apiKey';
import { sendSuccessMessage } from '../../stores/messages';
import { RootState } from '../../stores/rootReducer';
import { useAppDispatch, useAppSelector } from '../../stores/store';
import { EmptyResults } from '../common/EmptyResults';
import { LoadingSettings } from '../common/LoadingSettings';
import { ApiKeyData } from './ApiKey/ApiKeyData';
import { FirstApiKeyModal } from './ApiKey/FirstApiKeyModal';
import { ResetApiKeyModal } from './ApiKey/ResetApiKeyModal';

export const ApiKey = () => {
  const dispatch = useAppDispatch();
  const { colors } = useTheme();

  const [resetDialogIsOpen, setResetDialogIsOpen] = useState<boolean>(false);
  const [firstApiKeyModalOpen, setFirstApiKeyModalOpen] = useState<boolean>(false);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const { currentUser, error, isLoading } = useAppSelector((state: RootState) => ({
    currentUser: state.currentUser.currentUser as EnterpriseUser,
    error: state.currentUser.error,
    isLoading: state.currentUser.isLoading
  }));

  const { apiKey, apiKeyError, apiKeyIsLoading } = useAppSelector((state: RootState) => ({
    apiKey: state.apiKey?.apiKey,
    apiKeyError: state.apiKey?.error,
    apiKeyIsLoading: state.apiKey?.isLoading
  }));

  useEffect(() => {
    if (!apiKey && currentUser) {
      dispatch(fetchApiKey(currentUser.enterprise.id));
    }
  }, [apiKey, dispatch, currentUser]);

  const showApiKey = (viewOnly?: boolean) => {
    if (apiKey) {
      if (apiKey.unencryptedKey) {
        return (
          <Flex alignItems="center" m={0} p={0}>
            <textarea
              ref={textareaRef}
              readOnly
              value={apiKey.unencryptedKey}
              className={copyableTextStyles.selectableTextarea}
              style={{ display: viewOnly ? 'none' : 'block' }} />

            <Text.Body
              display={viewOnly ? 'block' : 'none'}
              overflow="hidden"
              maxWidth="85%"
              style={{ textOverflow: 'ellipsis' }}
              mr={1}
              my={0}>

              {apiKey.unencryptedKey}
            </Text.Body>
            <Button.Tertiary
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(apiKey.unencryptedKey).then(() => {
                  dispatch(sendSuccessMessage(t("Copied to clipboard", null, { project: 'enterprise-dashboard' })));
                });
              }}
              aria-label={t("Copy", {}, { project: 'enterprise-dashboard' })}>

              <Duplicate />
            </Button.Tertiary>
          </Flex>);

      }
      return <Text.Body>{apiKey?.first4?.padEnd(API_KEY_LENGTH, '•')}</Text.Body>;
    }
  };

  const openResetApiKeyModal = () => {
    setResetDialogIsOpen(true);
  };
  const openFirstApiKeyModal = () => {
    setFirstApiKeyModalOpen(true);
  };

  const closeFirstApiKeyModal = () => {
    setFirstApiKeyModalOpen(false);
    textareaRef.current?.focus();
  };

  const triggerResetApiKey = (closeModals?: boolean) => {
    dispatch(resetApiKey(currentUser.enterprise.id));
    if (closeModals) {
      setResetDialogIsOpen(false);
    }
    dispatch(sendSuccessMessage(t("Your API key has been reset.", null, { project: 'enterprise-dashboard' })));
  };

  if (!firstApiKeyModalOpen && (!apiKey || apiKeyIsLoading || isLoading)) {
    return <LoadingSettings />;
  }

  return (
    <>
      {apiKey?.first4 ?
      <ApiKeyData
        openApiKeyModal={openResetApiKeyModal}
        currentUser={currentUser}
        apiKey={apiKey}
        showApiKey={showApiKey} /> :


      <EmptyResults
        title={t("You don\u2019t have any API keys yet.", {}, { project: 'enterprise-dashboard' })}
        subtitle={
        <>
              <T project="enterprise-dashboard">{"API keys give you the ability to develop your own software with our service."}</T>


              <br />
              <TextLink href="https://enterprise-scheduling.readme.io/" target="_blank" rel="nofollow noreferrer">
                <T project="enterprise-dashboard">{"See API doc."}</T>
              </TextLink>
            </>
        }
        actionButton={
        <Button.Primary type="button" onClick={openFirstApiKeyModal} size="large">
              <T project="enterprise-dashboard">{"Generate New API Key"}</T>
            </Button.Primary>
        } />

      }
      <Modal.Transition>
        {resetDialogIsOpen &&
        <ResetApiKeyModal
          closeModal={() => setResetDialogIsOpen(false)}
          resetApiKey={() => triggerResetApiKey(true)} />

        }
        {firstApiKeyModalOpen &&
        <FirstApiKeyModal
          closeModal={closeFirstApiKeyModal}
          resetApiKey={triggerResetApiKey}
          showApiKey={showApiKey} />

        }
      </Modal.Transition>
    </>);

};