import { Enterprise } from '../models/Enterprise';

export const enterpriseHasSsoProvider = (enterprise?: Enterprise | null) => {
  return enterprise?.flowType === 'SSO' || enterprise?.flowType === 'SSO_SAML';
};

export const ssoProviderMap = (provider?: string) => {
  let mappedProvider = '';
  switch (provider) {
  case 'FACEBOOK':
    mappedProvider = 'Facebook';
    break;
  case 'GOOGLE':
    mappedProvider = 'Google';
    break;
  case 'APPLE':
    mappedProvider = 'Apple';
    break;
  case 'TWITTER':
    mappedProvider = 'Twitter';
    break;
  case 'OKTA':
    mappedProvider = 'Okta';
    break;
  case 'CAPITALONE':
    mappedProvider = 'CapitalOne';
    break;
  case 'TARGET':
    mappedProvider = 'Target';
    break;
  case 'CLASSLINK':
    mappedProvider = 'ClassLink';
    break;
  case 'AZURE':
    mappedProvider = 'Azure';
    break;
  case 'STUB_THIRD_PARTY':
    mappedProvider = 'Third Party';
    break;
  case 'STUB_SSO':
    mappedProvider = 'SSO';
    break;
  case 'PASSKEY':
    mappedProvider = 'Passkey';
    break;
  case 'GOOGLE_SSO':
    mappedProvider = 'Google SSO';
    break;
  case 'BOLDBRUSH':
    mappedProvider = 'BoldBrush';
    break;
  case 'ENTERPRISE_ORG':
    mappedProvider = 'Enterprise Org';
    break;
  case 'DELL':
    mappedProvider = 'Dell';
    break;
  default:
    mappedProvider = 'N/A';
  }

  return mappedProvider;
};
