import { ExclamationMarkCircle } from '@sqs/rosetta-icons';
import { Box, Button, Flex, Text } from '@sqs/rosetta-primitives';
import React from 'react';
import { T } from '../../../i18n';
import { GenericModal } from '../../common/GenericModal';
import { ModalTitle } from '../../common/ModalTitle';

interface ResetApiKeyModalProps {
  readonly closeModal: () => void;
  readonly resetApiKey: () => void;
}

export const ResetApiKeyModal = ({ closeModal, resetApiKey }: ResetApiKeyModalProps) => {
  return (
    <GenericModal
      closeModal={closeModal}
      modalActions={
      <Flex justifyContent="space-between" gap={1} width="100%">
          <Button.Tertiary size="medium" type="button" onClick={closeModal}>
            <T project="enterprise-dashboard">{"Cancel"}</T>
          </Button.Tertiary>
          <Button.Tertiary color="red" size="medium" type="button" onClick={() => resetApiKey()}>
            <T project="enterprise-dashboard">{"Reset"}</T>
          </Button.Tertiary>
        </Flex>
      }>

      <Box height="100%">
        <Flex flexDirection="column" height="100%" alignItems="flex-start">
          <Flex mb={2} gap={1}>
            <ExclamationMarkCircle color="red" />
            <ModalTitle>
              <T project="enterprise-dashboard">{"Reset API Key?"}</T>
            </ModalTitle>
          </Flex>
          <Box mb={5}>
            <Text.Body>
              <T project="enterprise-dashboard">{"A new API Key will be generated, and your current one will stop working immediately. This cannot be undone."}</T>



            </Text.Body>
          </Box>
        </Flex>
      </Box>
    </GenericModal>);

};