import { t } from '../i18n';
import { PageInfoQueryWithSearch } from '../models/PaginatedResponse';
import {
  SchedulingInstanceCalendarResults,
  SchedulingInstanceData,
  SchedulingInstanceFetchResponse,
  SchedulingInstanceFetchSingleResponse } from
'../models/SchedulingInstance';
import { V2_API_BASE_URL, V2_ENTERPRISE_BASE_URL } from './api';
import axios from './axios';
import { appendPaginationInfo } from './helpers';

export interface ValidateSubdomainResponse {
  error?: boolean;
  message?: string;
  status?: number;
  available?: boolean;
}

export interface AddUserToSchedulingInstanceData {
  permissions: string;
  allowExports: boolean;
  calendars: number[];
  allCalendars: boolean;
}

export const validateSubdomain = async (value: string): Promise<ValidateSubdomainResponse> => {
  const url = value.replace(/ /g, '');
  if (!url) {
    // off-chance empty responses should not prevent form submission
    return { status: 204 };
  }
  try {
    const response = await axios.get(`${V2_API_BASE_URL}/validate-subdomain/${url}`);
    const res: ValidateSubdomainResponse = response.data;
    return res;
  } catch (error) {
    return Promise.reject({
      error: true,
      message: t("An error occurred while validating the subdomain. Please try again or leave this field blank and fill out later.",

      null, {
        project: 'enterprise-dashboard' })

    });
  }
};

export const listSchedulingInstances = async (
enterpriseId: number,
pageInfo?: PageInfoQueryWithSearch)
: Promise<SchedulingInstanceFetchResponse> => {
  const url = appendPaginationInfo(new URL(`${V2_ENTERPRISE_BASE_URL}/${enterpriseId}/instances`), pageInfo);

  const response = await axios.get<SchedulingInstanceFetchResponse>(url.toString());
  return response.data;
};

export const createSqspSchedulingInstance = async (
enterpriseId: number,
schedulingInstanceData: SchedulingInstanceData)
: Promise<SchedulingInstanceFetchSingleResponse> => {
  const response = await axios.post<SchedulingInstanceFetchSingleResponse>(
    `${V2_ENTERPRISE_BASE_URL}/${enterpriseId}/instances`,
    {
      ...schedulingInstanceData
    }
  );
  return response.data;
};

export const deleteSqspSchedulingInstance = async (enterpriseId: number, schedulingInstanceId: number) => {
  const response = await axios.delete<void>(
    `${V2_ENTERPRISE_BASE_URL}/${enterpriseId}/instance/${schedulingInstanceId}`
  );
  return response.data;
};

export const fetchSchedulingInstanceCalendars = async (
enterpriseId: number,
schedulingInstanceId: number,
pageInfo?: PageInfoQueryWithSearch)
: Promise<SchedulingInstanceCalendarResults> => {
  const url = appendPaginationInfo(
    new URL(`${V2_ENTERPRISE_BASE_URL}/${enterpriseId}/instance/${schedulingInstanceId}/calendars`),
    pageInfo
  );

  const response = await axios.get<SchedulingInstanceCalendarResults>(url.toString());
  return response.data;
};

export const addUserToSchedulingInstance = async (
enterpriseId: number,
instanceId: number,
userId: number,
formData: AddUserToSchedulingInstanceData) =>
{
  const response = await axios.post(
    `${V2_ENTERPRISE_BASE_URL}/${enterpriseId}/instance/${instanceId}/users/${userId}`,
    {
      ...formData
    }
  );
  return response.data;
};

export const updateUserInSchedulingInstance = async (
enterpriseId: number,
instanceId: number,
userId: number,
formData: AddUserToSchedulingInstanceData) =>
{
  const response = await axios.patch(
    `${V2_ENTERPRISE_BASE_URL}/${enterpriseId}/instance/${instanceId}/users/${userId}`,
    {
      ...formData
    }
  );
  return response.data;
};

export const fetchOrGenerateUserPermissionsForSchedulingInstance = async (
enterpriseId: number,
schedulingInstanceId: number) =>
{
  const response = await axios.get(
    `${V2_ENTERPRISE_BASE_URL}/${enterpriseId}/instance/${schedulingInstanceId}/permissions/view`
  );
  return response.data;
};

export const getUserPermissionsForSchedulingInstance = async (
enterpriseId: number,
schedulingInstanceId: number,
userId: number) =>
{
  const response = await axios.get(
    `${V2_ENTERPRISE_BASE_URL}/${enterpriseId}/instance/${schedulingInstanceId}/users/${userId}/permissions`
  );

  return response.data;
};

export const removeUserFromInstance = async (enterpriseId: number, instanceId: number, userId: number) => {
  const response = await axios.delete(
    `${V2_ENTERPRISE_BASE_URL}/${enterpriseId}/instance/${instanceId}/users/${userId}`
  );
  return response.data;
};